<template>
  <div class="page-content">
    <div class="container-fluid">
      <Breadcrumb
        :pgTitle="$t('nav.countries')"
        :pgIcon="'bx bx-map'"
        :refs="refs"
        :pgCurrent="pgCurrent"
        :addNew="false"
      >
      </Breadcrumb>

      <div class="row">
        <Loading v-if="pgLoading"> </Loading>

        <div v-if="!pgLoading" class="col-8 col-sm-12">
          <FormElements
            :label="$t('view.country_info')"
            :labelTitle="$t('view.country_title')"
            :hasTitle="true"
            :title_ar="row.title_ar"
            :title_en="row.title_en"
            v-on:formChange="handleFormChange"
          >
          </FormElements>
        </div>

        <div v-if="!pgLoading" class="col-4 col-sm-12">
          <CardInput
            :label="$t('view.country_code')"
            :input="row.code"
            @inputChange="handleCountryCodeChange"
          />

          <CardInput
            :label="$t('view.time_zone')"
            :input="row.time_zone"
            @inputChange="handleTimeZoneChange"
          />

          <CardImage
            :labelImage="$t('view.icon')"
            :upload_icon="true"
            :image_base64="row.image_base64"
            :image_preview="row.image_preview"
            v-on:imageChange="handleImageChange"
          >
          </CardImage>

          <CardStatus
            :status="row.status"
            v-on:statusChange="handleStatusChange"
          >
          </CardStatus>
        </div>
      </div>

      <Buttons
        v-if="!pgLoading"
        :btnLoading="btnLoading"
        :btnCurrent="btnCurrent"
        v-on:submitClicked="handelSubmit"
        v-on:cancelClicked="handleCancel"
      >
      </Buttons>
    </div>
  </div>
</template>

<script>
import iziToast from "izitoast";
import CardInput from "@/components/CardInput";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Loading from "@/components/Loading.vue";
import FormElements from "@/components/FormElements.vue";
import CardImage from "@/components/CardImage.vue";
import Buttons from "@/components/Buttons.vue";
import CardStatus from "@/components/CardStatus.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default {
  name: "EditOrNew",
  components: {
    CardInput,
    Breadcrumb,
    Loading,
    FormElements,
    CardImage,
    Buttons,
    CardStatus,
  },
  data() {
    return {
      // auth
      auth: {
        role: "",
        id_token: "",
      },

      // row
      row: {
        code: "",
        time_zone: "",
        title_ar: "",
        title_en: "",
        image_base64: "",
        image_preview: "",
        status: 1,
      },

      msgCurrent: this.$route.params.id ? "Updated" : "Created",
      btnCurrent: this.$route.params.id ? "Update" : "Create",
      methodType: this.$route.params.id ? "PUT" : "POST",
      pgLoading: this.$route.params.id ? true : false,
      pgCurrent: this.$route.params.id ? "Edit" : "Add New",
      btnLoading: false,

      refs: "countries",
    };
  },
  created() {
    // AccessToken & Role
    if (localStorage.getItem("id_token")) {
      this.auth.id_token = localStorage.getItem("id_token");
    }
    if (localStorage.getItem("role_key")) {
      this.auth.role = localStorage.getItem("role_key");
    }

    if (this.$route.params.id) {
      this.fetchRow();
    }
    setCurrentPageTitle(this.$t("nav.countries"), "bx bx-map");
  },
  methods: {
    // fetch Row
    fetchRow() {
      this.pgLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.id_token,
      };
      const options = {
        url: this.refs + "/" + this.$route.params.id,
        method: "GET",
        data: {},
      };
      this.axios(options)
        .then((res) => {
          this.pgLoading = false;
          this.row.image_preview = res.data.row.image
            ? res.data.row.image.url
            : "";
          this.row.image_base64 = res.data.row.image
            ? res.data.row.image.url
            : "";
          this.row.id = res.data.row.id;
          this.row.code = res.data.row.code;
          this.row.time_zone = res.data.row.time_zone;
          this.row.title_ar = res.data.row.ar.title;
          this.row.title_en = res.data.row.en.title;
          this.row.status = res.data.row.status;
        })
        .catch((err) => {
          // 403 Forbidden
          if (err.response && err.response.status == 401) {
            this.clearLocalStorage();
            this.$router.push({ name: "login" });
          } else if (err.response && err.response.status == 403) {
            this.$router.push({ name: "forbidden" });
          } else {
            this.btnLoading = false;
            iziToast.warning({
              icon: "bx bx-angry",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          }
        });
    },

    // handle edit Or New submit
    handelSubmit() {
      this.btnLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.id_token,
      };

      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const options = {
        url: this.$route.params.id
          ? this.refs + "/" + this.$route.params.id
          : this.refs,
        method: this.methodType,
        data: {
          ar: {
            title: this.row.title_ar,
          },

          en: {
            title: this.row.title_en,
          },
          code: this.row.code,
          time_zone: this.row.time_zone,
          status: this.row.status,
          image_base64: this.row.image_base64,
        },
      };
      this.axios(options, config)
        .then(() => {
          this.btnLoading = false;
          iziToast.success({
            icon: "bx bx-wink-smile",
            title: "",
            message:
              this.btnCurrent == "Update"
                ? "تم التعديل بنجاح"
                : "تم الإضافة بنجاح",
          });

          this.$router.push({ name: this.refs });
        })
        .catch((err) => {
          // 403 Forbidden
          if (err.response && err.response.status == 401) {
            this.clearLocalStorage();
            this.$router.push({ name: "login" });
          } else if (err.response && err.response.status == 403) {
            this.$router.push({ name: "forbidden" });
          } else {
            this.btnLoading = false;
            iziToast.warning({
              icon: "bx bx-angry",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          }
        });
    },

    // clear Local Storage
    clearLocalStorage() {
      localStorage.removeItem("id_token");
      localStorage.removeItem("avatar");
      localStorage.removeItem("username");
      localStorage.removeItem("encrypt_id");
      localStorage.removeItem("role");
    },

    // Cancel
    handleCancel() {
      if (confirm("هل انت متأكد؟")) {
        this.$router.push({ name: this.refs });
      }
    },

    // handel Changes from child components
    handleFormChange(event) {
      this.row.title_ar = event.title_ar;
      this.row.title_en = event.title_en;
    },

    handleStatusChange(event) {
      this.row.status = event.status;
    },
    handleImageChange(event) {
      this.row.image_base64 = event.image_base64;
    },
    handleCountryCodeChange(event) {
      this.row.code = event.input;
    },
    handleTimeZoneChange(event) {
      this.row.time_zone = event.input;
    },
  },
};
</script>
