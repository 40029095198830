<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12" style="display: flex">
      <div
        class="
          row
          col-12
          page-title-box
          d-flex
          align-items-center
          justify-content-between
        "
      >
        <h4
          class="mb-0"
          :class="$i18n.locale == 'ar' ? 'font-size-24' : 'font-size-16'"
        >
          <router-link
            v-if="addNew"
            :to="'/' + refs + '/create'"
            class="btn btn-sm btn-light-primary"
            :class="[
              $i18n.locale == 'ar' ? 'hacen_algeria' : '',
              theme_color == 'purple' ? 'btn-purple' : '',
            ]"
          >
            <i class="mdi mdi-plus-circle"></i>
            {{ $t("app.addNew") }}
          </router-link>
          <a v-else class="btn btn-primary mb-2 opacity-0">hidden</a>
        </h4>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>

<script>
export default {
  name: "Breadcrumb",
  props: ["pgCurrent", "pgTitle", "pgIcon", "addNew", "refs"],
  data() {
    return {
      auth: {
        role: "",
      },
      row: {
        slug: "",
        parent_id: "",
      },
      theme_color: "",
    };
  },
  watch() {
    //
  },
  created() {
    if (localStorage.getItem("role_key")) {
      this.auth.role = localStorage.getItem("role_key");
    }
    if (localStorage.getItem("theme_color")) {
      this.theme_color = localStorage.getItem("theme_color");
    }

    if (this.$route.query.parent_id) {
      this.row.parent_id = this.$route.query.parent_id;
    }

    if (this.$route.query.slug) {
      this.row.slug = this.$route.query.slug;
    }
  },
};
</script>
